import { CardSliderPagination } from './card-slider-pagination'

export const state = {
  cardSliderPagination: undefined,
  openedCard: undefined,
  preventCardHover: false,
  preventCardClick: false,
  hoveredCard: {
    currentlyHoveredCard: undefined,
    easingFactor: 0,
    smallImg: null,
    fullSizeImg: null,
    mouseDidLeaveCards: new Set<HTMLElement>(),
    halfCardWidth: 0,
    halfCardHeight: 0,
  },
} as {
  cardSliderPagination: CardSliderPagination | undefined
  openedCard: HTMLElement | undefined
  preventCardHover: boolean
  preventCardClick: boolean
  hoveredCard: {
    currentlyHoveredCard: HTMLElement | undefined
    easingFactor: number
    smallImg: HTMLElement | null
    fullSizeImg: HTMLElement | null
    mouseDidLeaveCards: Set<HTMLElement>
    halfCardWidth: number
    halfCardHeight: number
  }
}
