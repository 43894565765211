export const easeInCirc = (x: number) => 1 - Math.sqrt(1 - Math.pow(x, 2))

export const easeOutCirc = (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2))

export const easeInOutCubic = (x: number) =>
  x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2

export const easeOutSine = (x: number) => Math.sin((x * Math.PI) / 2)

export const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2

export const easeOutQuart = (x: number) => 1 - Math.pow(1 - x, 4)

export const easeInOutQuart = (x: number) =>
  x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2

export const easeOutExpo = (x: number) =>
  x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
