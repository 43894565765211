export const CARD_HEIGHT_TO_WIDTH_RATIO = 1.62
export const CARD_FULL_SIZE_IMAGE_SCALE_FACTOR = 1.05
export const CARD_OPENED_SCALE_FACTOR = 1.23
export const SMALL_BREAKPOINT = 768

// NOTE: Getting the actual CSS var. values from the computed style of the
// document breaks intermittently in Safari, even though this function is ran
// after window DOMContentLoaded or load.
// export const initializeCssVariables = () => {
//   const documentComputedStyle = getComputedStyle(document.documentElement)

//   CARD_HEIGHT_TO_WIDTH_RATIO = parseFloat(
//     documentComputedStyle.getPropertyValue('--card-height-to-width-ratio')
//   )
//   CARD_FULL_SIZE_IMAGE_SCALE_FACTOR = parseFloat(
//     documentComputedStyle.getPropertyValue(
//       '--card-full-size-image-scale-factor'
//     )
//   )
//   CARD_OPENED_SCALE_FACTOR = parseFloat(
//     documentComputedStyle.getPropertyValue('--card-opened-scale-factor')
//   )
//   SMALL_BREAKPOINT = parseInt(
//     documentComputedStyle.getPropertyValue('--small-breakpoint')
//   )
// }
