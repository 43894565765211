const footer = () => {
  const footerEl = document.querySelector('.footer')
  const timeEl = document.querySelector('.footer-time') as HTMLElement
  const hourEl = document.querySelector('.footer-time-hour') as HTMLElement
  const minuteEl = document.querySelector('.footer-time-minute') as HTMLElement

  if (!footerEl) return

  if (timeEl && hourEl && minuteEl) {
    let lastHour, lastMinute

    const writeTime = () => {
      const now = new Date()

      now.setHours(now.getHours() + Math.abs(now.getTimezoneOffset() / 60))

      const hour = now.getUTCHours()
      const minute = now.getMinutes()

      if (!lastHour || lastHour !== hour) {
        lastHour = hour
        hourEl.innerText = hour.toString()
        timeEl.setAttribute('datetime', now.toDateString())
      }

      if (!lastMinute || lastMinute !== minute) {
        lastHour = hour
        minuteEl.innerText = minute.toString().padStart(2, '0')
        timeEl.setAttribute('datetime', now.toDateString())
      }
    }

    writeTime()

    setInterval(writeTime, 10000)
  }
}

export default footer
