export const clickableTagNames = [
  'A',
  'BUTTON',
  'INPUT',
  'LABEL',
  'OPTION',
  'SELECT',
  'TEXTAREA',
  'VIDEO',
  'AUDIO',
]
