import { SMALL_BREAKPOINT } from '../../utils/css-variables'
import { animate, cancelOngoingAnimations } from '../../utils/animate'
import { isTouchDevice } from '../../utils/is-touch-device'
import { easeInOutQuart } from '../../utils/easings'
import { clickableTagNames } from '../../utils/clickable-tagnames'
import {
  addCardHoverEventHandlers,
  resetCardHoverState,
} from './card-hover-event-handlers'
import { CardSliderPagination } from './card-slider-pagination'
import { state } from './card-slider-state'
import { addCardTouchEventHandlers } from './card-touch-event-handlers'

interface TouchEvent extends Event {
  targetTouches?: TouchList
  changedTouches?: TouchList
}

interface CardSliderOptions {
  scrollableContainer: HTMLElement
  onOpen: () => void
  onClose: () => void
  onLoadedImages: () => void
}

interface ScrollAnimationOptions {
  duration: number
  easing: (x: number) => number
}

const s = state

const CARD_OPEN_CLOSE_DURATION = 850
const CARD_TO_FROM_VIEWPORT_DURATION = 900
const CARD_TO_FROM_VIEWPORT_TRANSITION = `
  width ${CARD_TO_FROM_VIEWPORT_DURATION}ms cubic-bezier(.6, .1, 0, .95),
  height ${CARD_TO_FROM_VIEWPORT_DURATION}ms cubic-bezier(.6, .1, 0, .95),
  border-radius ${CARD_TO_FROM_VIEWPORT_DURATION}ms cubic-bezier(.6, .1, 0, .95) .6s,
  top ${CARD_TO_FROM_VIEWPORT_DURATION}ms cubic-bezier(.6, .1, 0, .95),
  left ${CARD_TO_FROM_VIEWPORT_DURATION}ms cubic-bezier(.6, .1, 0, .95)
`

const SLOW_SCROLL_OPTIONS: ScrollAnimationOptions = {
  duration: 1200,
  easing: easeInOutQuart,
}

const cardSlider = document.querySelector('.card-slider') as HTMLElement
let cardSliderOptions: CardSliderOptions
let cardSliderScrollableContainer: HTMLElement
let cards: HTMLElement[] = []
let cardSliderPreviousButton: HTMLElement | undefined
let cardSliderNextButton: HTMLElement | undefined

const specificCards = {}

const openCard = (card: HTMLElement, isFirstCardOpened = false) => {
  const cardId = card.getAttribute('data-card-id')

  resetCardHoverState()

  state.preventCardHover = true

  setTimeout(() => {
    state.preventCardHover = false
  }, CARD_OPEN_CLOSE_DURATION)

  card.classList.add('card-is-opened', 'card-with-revealed-content')

  if (isFirstCardOpened) {
    card.classList.add('card-is-first-card-opened')
  }

  if (cardId && specificCards[cardId]) {
    specificCards[cardId].onOpen(card)
  }
}

const closeCard = (card: HTMLElement) => {
  const cardId = card.getAttribute('data-card')

  state.preventCardHover = true

  setTimeout(() => {
    state.preventCardHover = false
  }, CARD_OPEN_CLOSE_DURATION)

  card.removeAttribute('style')
  card.classList.remove(
    'card-is-opened',
    'card-is-first-card-opened',
    'card-with-revealed-content'
  )

  if (cardId && specificCards[cardId]) {
    specificCards[cardId].onClose(card)
  }
}

const expandCardToViewport = (card: HTMLElement) => {
  const { left, top } = card.getBoundingClientRect()

  card.style.transition = CARD_TO_FROM_VIEWPORT_TRANSITION
  card.style.top = top > 0 ? `-${top}px` : `${Math.abs(top)}px`
  card.style.left = left > 0 ? `-${left}px` : `${Math.abs(left)}px`
  card.style.width = `${window.innerWidth}px`
  card.style.height = `${window.innerHeight}px`
  card.style.borderRadius = '0'
  ;(card.parentNode as HTMLElement).style.zIndex = '10'

  setTimeout(() => {
    card.removeAttribute('style')
    ;(card.parentNode as HTMLElement).removeAttribute('style')
  }, CARD_TO_FROM_VIEWPORT_DURATION)
}

const contractCardFromViewport = (card: HTMLElement) => {
  const { left, top, width, height } = (
    card.parentNode as HTMLElement
  ).getBoundingClientRect()

  card.style.top = top > 0 ? `-${top}px` : `${Math.abs(top)}px`
  card.style.left = left > 0 ? `-${left}px` : `${Math.abs(left)}px`
  card.style.width = `${window.innerWidth}px`
  card.style.height = `${window.innerHeight}px`
  ;(card.parentNode as HTMLElement).style.zIndex = '10'

  setTimeout(() => {
    card.style.transition = CARD_TO_FROM_VIEWPORT_TRANSITION
    card.style.top = '0px'
    card.style.left = '0px'
    card.style.width = `${width}px`
    card.style.height = `${height}px`
    card.style.borderRadius = 'var(--u)'

    setTimeout(() => {
      card.removeAttribute('style')
      ;(card.parentNode as HTMLElement).removeAttribute('style')
    }, CARD_TO_FROM_VIEWPORT_DURATION)
  }, 10)
}

const scrollCardToCenter = (
  card: HTMLElement,
  scrollableContainer: HTMLElement,
  shouldAnimate = false,
  animationOptions?: ScrollAnimationOptions
) => {
  // NOTE: card is likely to be transformed and scaled at this point,
  // card.parentNode is the untransformed <li> containing this card.
  // Not using the parentNode will result in a faulty scroll position
  // since the card transformations will be removed during the scrolling.
  const { width, x } = (card.parentNode as HTMLElement).getBoundingClientRect()
  const centerScrollLeft = x + width / 2 - window.innerWidth / 2

  if (shouldAnimate) {
    const { duration, easing } = animationOptions ?? {}

    cancelOngoingAnimations()

    animate(
      (scrollLeft: number) => (scrollableContainer.scrollLeft = scrollLeft),
      scrollableContainer.scrollLeft,
      scrollableContainer.scrollLeft + centerScrollLeft,
      duration ?? 1000,
      easing
    )
  } else {
    scrollableContainer.scrollLeft += centerScrollLeft
  }
}

const goToPreviousCard = (scrollAnimationOptions?: ScrollAnimationOptions) => {
  const previousCardIndex = s.openedCard
    ? Math.max(0, cards.indexOf(s.openedCard)) - 1
    : 0
  const previousCard = cards[previousCardIndex]

  if (previousCard) {
    goToCard(previousCard, scrollAnimationOptions)
  } else {
    closeCardSlider()
  }
}

const goToNextCard = (scrollAnimationOptions?: ScrollAnimationOptions) => {
  const nextCardIndex = s.openedCard
    ? Math.min(cards.length && cards.indexOf(s.openedCard) + 1)
    : 0
  const nextCard = cards[nextCardIndex]

  if (nextCard) {
    goToCard(nextCard, scrollAnimationOptions)
  } else {
    closeCardSlider()
  }
}

const goToCard = (
  card: HTMLElement,
  scrollAnimationOptions?: ScrollAnimationOptions
) => {
  const cardIndex = cards.indexOf(card)

  if (s.openedCard) {
    closeCard(s.openedCard)
  }

  openCard(card)

  s.cardSliderPagination?.update({
    currentCardIndex: cardIndex,
    theme: card.classList.contains('card-theme-dark') ? 'dark' : 'light',
    moveToElement: window.innerWidth >= SMALL_BREAKPOINT ? card : undefined,
    moveWithTransition: window.innerWidth >= SMALL_BREAKPOINT,
  })

  scrollCardToCenter(
    card,
    cardSliderScrollableContainer,
    window.innerWidth >= SMALL_BREAKPOINT,
    scrollAnimationOptions
  )

  s.openedCard = card

  updateCardClasses()
  updateNextPrevButtons()
}

const updateCardClasses = () => {
  let encounteredOpenedCard = false

  for (const card of cards) {
    if (card === s.openedCard) {
      card.classList.remove(
        'card-is-before-opened-card',
        'card-is-after-opened-card'
      )

      encounteredOpenedCard = true
    } else {
      if (s.openedCard && !encounteredOpenedCard) {
        card.classList.add('card-is-before-opened-card')
        card.classList.remove('card-is-after-opened-card')
      } else if (s.openedCard && encounteredOpenedCard) {
        card.classList.add('card-is-after-opened-card')
        card.classList.remove('card-is-remove-opened-card')
      } else if (!s.openedCard) {
        card.classList.remove(
          'card-is-before-opened-card',
          'card-is-after-opened-card'
        )
      }
    }
  }
}

const updateNextPrevButtons = () => {
  if (!s.openedCard) return
}

export const onClickOpenedCard = (event: Event, card: HTMLElement) => {
  const targetTagName =
    event && event.target && (event.target as Element).tagName
      ? (event.target as Element).tagName
      : undefined

  if (targetTagName && clickableTagNames.includes(targetTagName)) {
    return event.stopPropagation()
  }

  const eventPageX =
    (event as PointerEvent).pageX ??
    ((event as TouchEvent).targetTouches &&
    (event as TouchEvent).targetTouches?.length
      ? ((event as TouchEvent).targetTouches as TouchList)[0]?.pageX
      : undefined)

  s.cardSliderPagination?.startAutoProgress({ resetElapsedTime: true })

  if (eventPageX && eventPageX < card.offsetWidth / 2) {
    goToPreviousCard()
  } else if (eventPageX && eventPageX >= card.offsetWidth / 2) {
    goToNextCard()
  }
}

const openCardSliderAtCard = (card: HTMLElement) => {
  cardSliderOptions.onOpen()
  s.openedCard = card
  cardSliderScrollableContainer.style.overflow = 'hidden'

  openCard(card, true)
  updateCardClasses()
  updateNextPrevButtons()

  if (window.innerWidth < SMALL_BREAKPOINT) {
    s.preventCardClick = true

    expandCardToViewport(card)

    setTimeout(() => {
      cardSlider.classList.add('card-slider-is-open')

      scrollCardToCenter(card, cardSliderScrollableContainer)

      s.preventCardClick = false
    }, CARD_TO_FROM_VIEWPORT_DURATION)
  } else {
    cardSlider.classList.add('card-slider-is-open')

    scrollCardToCenter(
      card,
      cardSliderScrollableContainer,
      true,
      SLOW_SCROLL_OPTIONS
    )
  }

  s.cardSliderPagination = new CardSliderPagination({
    addToElement: window.innerWidth < SMALL_BREAKPOINT ? document.body : card,
    currentCardIndex: cards.indexOf(card),
    numberOfCards: cards.length,
    theme: card.classList.contains('card-theme-dark') ? 'dark' : 'light',
    fixed: true,
    appearDelay:
      window.innerWidth < SMALL_BREAKPOINT
        ? 600
        : CARD_TO_FROM_VIEWPORT_DURATION,
    onAutoProgress: () => {
      cardSlider.classList.remove('card-slider-was-progressed-by-user')
      goToNextCard(SLOW_SCROLL_OPTIONS)
    },
  })

  s.cardSliderPagination.startAutoProgress({ resetElapsedTime: true })
}

const closeCardSlider = () => {
  cardSliderOptions.onClose()
  s.cardSliderPagination?.remove()
  cardSlider.classList.remove(
    'card-slider-is-open',
    'card-slider-was-progressed-by-user'
  )
  cardSliderScrollableContainer.style.overflow = 'auto'
  cardSliderScrollableContainer.style.overflowY = 'hidden'

  if (s.openedCard && window.innerWidth < SMALL_BREAKPOINT) {
    s.preventCardClick = true

    closeCard(s.openedCard)
    contractCardFromViewport(s.openedCard)

    setTimeout(() => {
      s.preventCardClick = false
    }, CARD_TO_FROM_VIEWPORT_DURATION)
  } else if (s.openedCard) {
    closeCard(s.openedCard)
  }

  s.openedCard = undefined
  updateCardClasses()
}

const initializeCardSlider = async (options: CardSliderOptions) => {
  if (!cardSlider) {
    return
  }

  const { scrollableContainer, onLoadedImages } = options
  let previousWindowSize: number = 0

  cardSliderScrollableContainer = scrollableContainer
  cardSliderOptions = options
  cards = Array.from(
    document.querySelectorAll('.card:not(.card-last-spacer)')
  ) as HTMLElement[]

  window.addEventListener(
    'resize',
    () => {
      // NOTE: Since we are centering by scroll position in the scrollable
      // container we need to make sure we set the correct position if the
      // window is resized
      if (s.openedCard) {
        scrollCardToCenter(s.openedCard, scrollableContainer)
      }

      // NOTE: We want to prevent the hover effect when in the small layout,
      // since this layout size doesn't really support the hover interactions
      // very well.
      if (s.preventCardHover) {
        if (window.innerWidth >= SMALL_BREAKPOINT) {
          s.preventCardHover = false
        }
      }

      if (!s.preventCardHover) {
        if (window.innerWidth < SMALL_BREAKPOINT) {
          s.preventCardHover = true
        }
      }

      // NOTE: If we pass from the small layout to the large layout we
      // need to move the current pagination form the page and into the
      // opened card and vice-versa (we also need to reset the progress
      // to next card interval, to make the pagination animation synk)
      if (s.openedCard && s.cardSliderPagination) {
        if (
          window.innerWidth >= SMALL_BREAKPOINT &&
          previousWindowSize < SMALL_BREAKPOINT
        ) {
          s.cardSliderPagination.update({
            moveToElement: s.openedCard,
            moveWithTransition: false,
          })
          s.cardSliderPagination.startAutoProgress()
        } else if (
          s.openedCard &&
          window.innerWidth < SMALL_BREAKPOINT &&
          previousWindowSize >= SMALL_BREAKPOINT
        ) {
          s.cardSliderPagination.update({
            moveToElement: document.body,
            moveWithTransition: false,
          })
          s.cardSliderPagination.startAutoProgress()
        }
      }

      previousWindowSize = window.innerWidth
    },
    { passive: true }
  )

  // NOTE: Set up the previous/next card buttons
  cardSliderPreviousButton = document.querySelector(
    '.card-slider-previous-button'
  ) as HTMLElement | undefined
  cardSliderNextButton = document.querySelector('.card-slider-next-button') as
    | HTMLElement
    | undefined

  if (cardSliderNextButton) {
    cardSliderNextButton.addEventListener(
      'click',
      () => {
        cardSlider.classList.add('card-slider-was-progressed-by-user')
        s.cardSliderPagination?.startAutoProgress({ resetElapsedTime: true })
        goToNextCard()
      },
      { passive: true }
    )
  }

  if (cardSliderPreviousButton) {
    cardSliderPreviousButton.addEventListener(
      'click',
      () => {
        cardSlider.classList.add('card-slider-was-progressed-by-user')
        s.cardSliderPagination?.startAutoProgress({ resetElapsedTime: true })
        goToPreviousCard()
      },
      { passive: true }
    )
  }

  const addCardEventHandlers = () => {
    for (const card of cards) {
      const cardContent = card.querySelector(
        '.card-content'
      ) as HTMLElement | null

      if (!cardContent) continue

      cardContent.addEventListener(
        'click',
        event => {
          if (s.preventCardClick) return

          if (!s.openedCard) {
            card.removeAttribute('style')
            card
              .querySelector('.card-full-size-image')
              ?.removeAttribute('style')

            openCardSliderAtCard(card)
          } else if (
            s.openedCard === card &&
            window.innerWidth < SMALL_BREAKPOINT
          ) {
            onClickOpenedCard(event, card)
          }
        },
        { passive: true }
      )

      addCardTouchEventHandlers(card, cardContent)

      if (!isTouchDevice) {
        addCardHoverEventHandlers(card)
      }
    }
  }

  const preloadImages: Promise<void>[] = []

  for (const card of cards.slice(0, 3)) {
    for (const imgEl of card.querySelectorAll('img')) {
      const tempImg = document.createElement('img')

      if (imgEl.sizes && imgEl.srcset) {
        tempImg.sizes = imgEl.sizes
        tempImg.srcset = imgEl.srcset
      } else if (imgEl.src) {
        tempImg.src = imgEl.src
      }

      preloadImages.push(
        new Promise(resolve => {
          tempImg.onload = () => resolve()
          tempImg.onerror = () => resolve()
        })
      )
    }

    for (const videoEl of card.querySelectorAll('video[poster]')) {
      const tempImg = document.createElement('img')

      tempImg.src = videoEl.getAttribute('poster') as string

      preloadImages.push(
        new Promise(resolve => {
          tempImg.onload = () => resolve()
          tempImg.onerror = () => resolve()
        })
      )
    }
  }

  await Promise.all(preloadImages)

  setTimeout(() => {
    onLoadedImages && onLoadedImages()

    cardSlider.classList.remove('card-slider-is-to-be-revealed')
  }, 100)

  setTimeout(() => {
    cardSlider.classList.remove('card-slider-is-being-revealed')

    addCardEventHandlers()
  }, 1900)
}
  
window.addEventListener('keydown', function(event) {
  if (event.key === 'Escape') { // For most modern browsers
    closeCardSlider();
  } else if (event.keyCode === 27) { // For older browsers
    closeCardSlider();
  }
});

export default initializeCardSlider
