import { isSafariIos } from '../utils/is-safari-ios'
import initalizeCardSlider from '../components/card-slider/card-slider'
import initalizeFooter from '../components/footer'

window.addEventListener('DOMContentLoaded', () => {
  const pageElement = document.querySelector('.index-page')

  if (!pageElement) return

  const scrollableContainer = document.querySelector(
    '.index-page-scrollable-content'
  ) as HTMLElement

  if (isSafariIos) {
    scrollableContainer.classList.remove('stylized-scrollbar')
  }

  // NOTE: Firefox persists the scroll position and so we
  // reset it here, in other cases this is terrible UX,
  // but in this case it is probably what we want.
  scrollableContainer.scrollLeft = 0

  // NOTE: Hide/show .index-page-title if ir is overlayed by the first
  // card. Using an intersection observer to detect when the first card
  // overlays the title would be the more elegant and performant way.
  // However, due to a bug in Safari where the first card was interpreted
  // as intersecting (irregardless of if it actually was or not) when
  // transformed in any way made this solution seem more robust.
  const thresholdMarginPx = 30
  let isIndexTitleOverlaid = false
  let cardSliderX, indexPageTitleWidth

  const onWindowResize = () => {
    cardSliderX = (
      document.querySelector('.card-slider') as HTMLElement
    ).getBoundingClientRect().x
    indexPageTitleWidth = (
      document.querySelector('.index-page-title') as HTMLElement
    ).offsetWidth
  }

  const onScrollableContainerScroll = () => {
    if (
      !isIndexTitleOverlaid &&
      cardSliderX - scrollableContainer.scrollLeft <
        indexPageTitleWidth + thresholdMarginPx
    ) {
      isIndexTitleOverlaid = true
      pageElement.classList.add('index-page-hide-title')
    } else if (
      isIndexTitleOverlaid &&
      cardSliderX - scrollableContainer.scrollLeft >=
        indexPageTitleWidth + thresholdMarginPx
    ) {
      isIndexTitleOverlaid = false
      pageElement.classList.remove('index-page-hide-title')
    }
  }
  
 const onWheelScroll = (event) => {
   // Check if the card slider is open and exit the function if it is.
   if (pageElement.classList.contains('index-page-card-slider-is-open')) {
     event.preventDefault();
     return;
   }
 
   if (event.deltaX === 0) {
     event.preventDefault();
     scrollableContainer.scrollLeft += event.deltaY;
   }
 };
 
 const isNonTouchDevice = () => {
   if (window.matchMedia) {
     return !window.matchMedia('(pointer: coarse)').matches;
   }
   // Fallback to assuming non-touch device if matchMedia is not supported
   return true;
  };

  onWindowResize()

  window.addEventListener(
    'resize',
    () => {
      onWindowResize();
      onScrollableContainerScroll();
    },
    { passive: true }
  );
  
  scrollableContainer.addEventListener('scroll', onScrollableContainerScroll, {
    passive: true,
  });
  
  if (isNonTouchDevice()) {
    scrollableContainer.addEventListener('wheel', onWheelScroll, {
      passive: false,
    });
  }

  initalizeFooter()
  initalizeCardSlider({
    scrollableContainer,
    onOpen: () => {
      pageElement.classList.add('index-page-card-slider-is-open');
      // Optionally disable scrolling on the body or html to prevent background scrolling
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    },
    onClose: () => {
      pageElement.classList.remove(
        'index-page-card-slider-is-open',
        'index-page-card-is-hovered'
      )
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
      pageElement.classList.add('index-page-did-close-card-slider')

      setTimeout(() => {
        pageElement.classList.remove('index-page-did-close-card-slider')
      }, 400)
    },
    onLoadedImages: () => {
      setTimeout(() => {
        pageElement.classList.remove('index-page-is-to-be-revealed')

        setTimeout(() => {
          pageElement.classList.remove('index-page-is-being-revealed')
        }, 500)
      }, 1300)
    },

    // FIRST NOTE: Using an intersection observer to hide/show title
    // when the first card overlays it would probably be the more elegant
    // and performant way. Due to the bug in the note below I opted to
    // look at the card slider scroll position instead, since it seems
    // more robust.
    // SECOND NOTE: onCardMouseEnter/onCardMouseLeave are two hacks to
    // prevent .index-page-title from being hidden while hovering
    // the first card. For some reason, in Safari,
    // firstCardIntersectionObserver created in afterInit interprets
    // the first card as intersecting when hovering it. Even though
    // it is far from it.

    // onCardMouseEnter: card => {
    //   clearTimeout(onCardMouseLeaveTimeout)
    //   pageElement.classList.add('index-page-card-is-hovered')
    // },
    // onCardMouseLeave: card => {
    //   onCardMouseLeaveTimeout = setTimeout(() => {
    //     pageElement.classList.remove('index-page-card-is-hovered')
    //   }, CARD_OPEN_CLOSE_DURATION)
    // },
    // afterInit: (cardSlider, cards) => {
    //   const firstCardIntersectionObserver = new IntersectionObserver(
    //     entries => {
    //       for (const entry of entries) {
    //         if (entry.isIntersecting) {
    //           pageElement.classList.remove('index-page-hide-title')
    //         } else {
    //           pageElement.classList.add('index-page-hide-title')
    //         }

    //         break
    //       }
    //     },
    //     { rootMargin: '0px 0px 0px -400px', threshold: 1 }
    //   )

    //   firstCardIntersectionObserver.observe(cards[0])
    // },
  })
})