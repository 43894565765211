const AUTO_PROGRESS_DELAY = 6000

export class CardSliderPagination {
  element: HTMLElement
  isPaused = false
  onAutoProgress: () => void
  autoProgressTimeout: number | undefined
  autoProgressStartTime = 0
  autoProgressElapsedTime = 0

  constructor(options: {
    addToElement: HTMLElement
    currentCardIndex: number
    numberOfCards: number
    theme: 'light' | 'dark'
    fixed: boolean
    appearDelay?: number
    onAutoProgress: () => void
  }) {
    const {
      addToElement,
      currentCardIndex,
      numberOfCards,
      theme = 'light',
      fixed = true,
      appearDelay,
      onAutoProgress,
    } = options

    this.element = document.createElement('ul')
    this.onAutoProgress = onAutoProgress

    this.element.classList.add(
      'card-slider-pagination',
      `card-slider-pagination-theme-${theme}`,
      fixed ? 'card-slider-pagination-is-fixed' : ''
    )
    this.element.innerHTML = Array(numberOfCards)
      .fill(0)
      .map(
        (_, index) =>
          `<li class="${
            index < currentCardIndex
              ? 'previous-card'
              : index === currentCardIndex
              ? 'current-card'
              : ''
          }" role="presentation" />`
      )
      .join('')

    addToElement.appendChild(this.element)

    setTimeout(() => {
      if (this.element) {
        this.element.classList.add('card-slider-pagination-is-visible')
      }
    }, appearDelay ?? 20)
  }

  update(options: {
    currentCardIndex?: number
    theme?: 'light' | 'dark'
    fixed?: boolean
    moveToElement?: HTMLElement
    moveWithTransition?: boolean
  }) {
    const {
      currentCardIndex,
      theme,
      fixed,
      moveToElement,
      moveWithTransition = false,
    } = options

    if (typeof currentCardIndex === 'number') {
      const paginationElements = this.element.querySelectorAll('li')

      for (const [index, element] of paginationElements.entries()) {
        element.classList.remove('previous-card', 'current-card')

        if (index < currentCardIndex) {
          element.classList.add('previous-card')
          element.classList.remove('current-card')
        } else if (index === currentCardIndex) {
          element.classList.add('current-card')
          element.classList.remove('previous-card')
        } else {
          element.classList.remove('previous-card', 'current-card')
        }
      }
    }

    if (theme === 'dark') {
      this.element.classList.add('card-slider-pagination-theme-dark')
    } else {
      this.element.classList.remove('card-slider-pagination-theme-dark')
    }

    if (fixed === true) {
      this.element.classList.add('card-slider-pagination-is-fixed')
    } else if (fixed === false) {
      this.element.classList.remove('card-slider-pagination-is-fixed')
    }

    if (moveToElement) {
      if (moveWithTransition) {
        this.element.classList.remove('card-slider-pagination-is-visible')

        setTimeout(() => {
          moveToElement.appendChild(this.element)

          requestAnimationFrame(() => {
            this.element.classList.add('card-slider-pagination-is-visible')
          })
        }, 400)
      } else {
        moveToElement.appendChild(this.element)
      }
    }
  }

  remove() {
    const element = this.element

    this.stopAutoProgress()

    this.element.classList.remove('card-slider-pagination-is-visible')
    // @ts-ignore
    this.element = undefined

    setTimeout(() => {
      element.remove()
    }, 400)
  }

  startAutoProgress(
    options: { delay?: number; resetElapsedTime?: boolean } = {}
  ) {
    const { delay, resetElapsedTime = false } = options

    this.stopAutoProgress()

    if (resetElapsedTime) {
      this.autoProgressElapsedTime = 0
    }

    this.autoProgressStartTime = Date.now()
    this.autoProgressTimeout = setTimeout(() => {
      this.onAutoProgress()
      this.startAutoProgress()
      this.autoProgressElapsedTime = 0
    }, delay ?? AUTO_PROGRESS_DELAY)
  }

  stopAutoProgress() {
    clearTimeout(this.autoProgressTimeout)
    this.autoProgressTimeout = undefined
  }

  pauseAutoProgress() {
    if (this.isPaused) return

    this.stopAutoProgress()

    this.isPaused = true
    this.autoProgressElapsedTime += Date.now() - this.autoProgressStartTime
    this.element.classList.add('card-slider-pagination-is-paused')
  }

  unpauseAutoProgress() {
    if (!this.isPaused) return

    this.isPaused = false
    this.startAutoProgress({
      delay: AUTO_PROGRESS_DELAY - this.autoProgressElapsedTime,
    })
    this.element.classList.remove('card-slider-pagination-is-paused')
  }
}
