import {
  CARD_OPENED_SCALE_FACTOR,
  CARD_FULL_SIZE_IMAGE_SCALE_FACTOR,
} from '../../utils/css-variables'
import { state } from './card-slider-state'

const s = state
const sh = state.hoveredCard

const onMouseEnter = (card: HTMLElement) => {
  if (s.openedCard === card) {
    s.cardSliderPagination?.pauseAutoProgress()
  }

  const { width, height } = card.getBoundingClientRect()

  sh.currentlyHoveredCard = card
  sh.halfCardWidth = width / 2
  sh.halfCardHeight = height / 2

  sh.fullSizeImg = card.querySelector('.card-full-size-image')
  sh.smallImg = card.querySelector('.card-small-hover-image')

  if (sh.fullSizeImg) {
    sh.fullSizeImg.style.transition = 'none'
  }

  if (sh.smallImg) {
    sh.smallImg.style.transition = 'none'
  }
}

const onMouseLeave = (card: HTMLElement) => {
  if (s.openedCard === card) {
    s.cardSliderPagination?.unpauseAutoProgress()
  }

  sh.mouseDidLeaveCards.add(card)
  sh.currentlyHoveredCard = undefined
  sh.easingFactor = 0

  card.removeAttribute('style')
  sh.fullSizeImg?.removeAttribute('style')
  sh.smallImg?.removeAttribute('style')

  setTimeout(() => {
    sh.mouseDidLeaveCards.delete(card)
  }, 300)
}

export const resetCardHoverState = () => {
  sh.easingFactor = 0
  sh.fullSizeImg?.removeAttribute('style')
  sh.smallImg?.removeAttribute('style')
}

export const addCardHoverEventHandlers = (card: HTMLElement) => {
  card.addEventListener(
    'mouseenter',
    () => {
      if (s.preventCardHover || sh.mouseDidLeaveCards.has(card)) return

      onMouseEnter(card)
    },
    { passive: true }
  )

  card.addEventListener(
    'mousemove',
    event => {
      if (s.preventCardHover || sh.mouseDidLeaveCards.has(card)) return

      if (sh.currentlyHoveredCard !== card) {
        if (sh.currentlyHoveredCard) {
          onMouseLeave(sh.currentlyHoveredCard)
        }

        sh.currentlyHoveredCard = card

        onMouseEnter(card)
      }

      if (s.openedCard && sh.currentlyHoveredCard !== s.openedCard) {
        return
      }

      s.cardSliderPagination?.pauseAutoProgress()

      const { pageX, pageY } = event as MouseEvent
      const { x, y } = card.getBoundingClientRect()
      const offsetX = pageX - x
      const offsetY = pageY - y
      const distanceFromCenterX = offsetX - sh.halfCardWidth
      const distanceFromCenterY = offsetY - sh.halfCardHeight
      const isHoveringOpenedCard = card === s.openedCard

      if (sh.easingFactor < 1) {
        sh.easingFactor += isHoveringOpenedCard ? 0.02 : 0.04
      }

      const initialRotateX = isHoveringOpenedCard ? -4 : -5
      const initialRotateY = isHoveringOpenedCard ? 4 : 5
      const scale = isHoveringOpenedCard ? CARD_OPENED_SCALE_FACTOR : 1
      const rotateX =
        initialRotateX *
        (distanceFromCenterY / sh.halfCardHeight) *
        sh.easingFactor
      const rotateY =
        initialRotateY *
        (distanceFromCenterX / sh.halfCardWidth) *
        sh.easingFactor
      const translateZ = (isHoveringOpenedCard ? 15 : 35) * sh.easingFactor

      card.style.transition = 'none'
      card.style.transform = `scale(${scale}) rotateX(${rotateX}deg) rotateY(${rotateY}deg) translateZ(${translateZ}px)`

      if (!isHoveringOpenedCard) {
        const initialShadowSize = 30
        const shadowSize = initialShadowSize * sh.easingFactor

        card.style.boxShadow = `${-rotateY * 3}px ${
          rotateX * 3
        }px ${shadowSize}px 0 rgba(0, 0, 0, 0.17)`
      }

      if (sh.fullSizeImg) {
        const initialTranslation = isHoveringOpenedCard ? -5 : -6
        const initialScale = isHoveringOpenedCard
          ? CARD_FULL_SIZE_IMAGE_SCALE_FACTOR
          : CARD_FULL_SIZE_IMAGE_SCALE_FACTOR

        const scale = initialScale - 0.02 * sh.easingFactor
        const translateX =
          initialTranslation *
          (distanceFromCenterX / sh.halfCardWidth) *
          sh.easingFactor
        const translateY =
          initialTranslation *
          (distanceFromCenterY / sh.halfCardHeight) *
          sh.easingFactor

        sh.fullSizeImg.style.transition = 'none'
        sh.fullSizeImg.style.transform = `scale(${scale}) translate3d(${translateX}px, ${translateY}px, 0)`
      }

      if (sh.smallImg) {
        const initialTranslation = isHoveringOpenedCard ? -7 : -8
        const negativeScaleOffset = isHoveringOpenedCard ? 0.0275 : 0.0325

        const scale = 1 - negativeScaleOffset * sh.easingFactor
        const translateX =
          initialTranslation *
          (distanceFromCenterX / sh.halfCardWidth) *
          sh.easingFactor
        const translateY =
          initialTranslation *
          (distanceFromCenterY / sh.halfCardHeight) *
          sh.easingFactor

        sh.smallImg.style.transition = 'none'
        sh.smallImg.style.transform = `scale(${scale}) translate3d(${translateX}px, ${translateY}px, 0)`
      }
    },
    { passive: true }
  )

  card.addEventListener(
    'mouseleave',
    () => {
      if (s.preventCardHover || sh.mouseDidLeaveCards.has(card)) return

      onMouseLeave(card)
    },
    { passive: true }
  )
}
