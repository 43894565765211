import { SMALL_BREAKPOINT } from '../../utils/css-variables'
import { clickableTagNames } from '../../utils/clickable-tagnames'
import { onClickOpenedCard } from './card-slider'
import { state } from './card-slider-state'

const s = state
let touchStartEvent, touchStartTime

export const addCardTouchEventHandlers = (
  card: HTMLElement,
  cardContent: HTMLElement
) => {
  cardContent.addEventListener('touchstart', event => {
    if (s.preventCardClick) return

    if (s.openedCard === card && window.innerWidth < SMALL_BREAKPOINT) {
      // NOTE: We do not want to interfere with the native functionality
      // of clickable elements
      const targetTagName =
        event && event.target && (event.target as Element).tagName
          ? (event.target as Element).tagName
          : undefined

      if (targetTagName && clickableTagNames.includes(targetTagName)) {
        return
      }

      event.preventDefault()

      touchStartEvent = event
      touchStartTime = Date.now()

      s.cardSliderPagination?.pauseAutoProgress()
    }
  })

  cardContent.addEventListener('touchend', event => {
    if (s.preventCardClick) return

    if (s.openedCard === card && window.innerWidth < SMALL_BREAKPOINT) {
      const elapsedTouchTime = Date.now() - (touchStartTime || Date.now())

      s.cardSliderPagination?.unpauseAutoProgress()

      // NOTE: If the user kept the card pressed for longer than some
      // threshold, we assume that it was to pause the auto-progression.
      // Otherwise we don't care about the propagation of this event.
      if (elapsedTouchTime > 300) {
        event.preventDefault()
      } else {
        onClickOpenedCard(touchStartEvent, card)
      }
    }
  })
}
