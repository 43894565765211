import { easeOutQuart } from './easings'

let ongoingAnimations: Set<number> = new Set()

export const cancelOngoingAnimations = () => {
  for (const ongoingAnimation of Array.from(ongoingAnimations)) {
    clearInterval(ongoingAnimation)
  }
}

export const animate = (
  func: (progress: number) => void,
  from: number,
  to: number,
  duration = 900,
  easing = easeOutQuart,
  fps = 120
) => {
  const valueDifference = to - from
  const startTime = Date.now()
  const interval = setInterval(() => {
    const timePassed = Date.now() - startTime

    const progress = timePassed / duration
    const easedProgress = easing(progress)
    const easedValue = from + valueDifference * easedProgress

    requestAnimationFrame(() => func(easedValue))

    if (timePassed >= duration) {
      clearInterval(interval)
      ongoingAnimations.delete(interval)
    }
  }, 1000 / fps)

  ongoingAnimations.add(interval)

  return interval
}
